import React from 'react';

import "./Iframe.css";

const FullWidthIframe = ({ name, url }) => {
  return (
    <div className='iframe-grid' style={{ width: '100%', height: '100%'}}>
      <iframe
        src={url}
        style={{ width: '100%', height: '100%', border: 'none', position: 'absolute', top: 0, left: 0 }}
        title={name}
        allowFullScreen
        allow="fullscreen"
      />
    </div>
  )
}

export default FullWidthIframe;
