import React from 'react';
import {
    Typography, Box, Chip, Divider
} from '@mui/material';



import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(4),
    },
    sectionTitle: {
      marginBottom: theme.spacing(3),
    },
    criteriaGroup: {
      marginBottom: theme.spacing(3),
    },
    criteriaItem: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    parityIcon: {
      fontSize: '2rem',
      color: theme.palette.primary.main,
    },
    shapeIcons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    frequencyText: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  }));
  
const CriteriaSummary = ({ evenCount, oddCount, shape = undefined, count }) => {

    const classes = useStyles();

    return <>
    <Box m={1}>
        <Typography variant="h6" className={classes.criteriaItem}>
            PARITE  
            <Divider color="primary" />
        </Typography>
    </Box>

    <Box m={1}>
        <Chip
            label={`${evenCount} PAIR`}
            className={classes.chip}
            color="primary"
            size='medium'
        />
    </Box>
    <Box m={1}>
        <Chip
            label={`${oddCount} IMPAIR`}
            className={classes.chip}
            color="warning"
            size='medium'
        />
    </Box>
    {shape && <Box m={1}>
        <Chip
            label={`FORME : ${shape}`}
            className={classes.chip}
            color="info"
            size='medium'
        />
    </Box>
    }
    <Box m={1}>
        <Chip
            label={`${count} TIRAGES`}
            className={classes.chip}
            color="default"
            size='medium'
        />
    </Box>
    </>
}

export default CriteriaSummary;