export default (theme) => ({
    container: {
      paddingTop: theme.spacing(4),
    },
    filters: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    filterGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    filterTitle: {
      // fontWeight: 'bold',
    },
    chipContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      flexWrap: 'wrap',
    },
    chipContainerInline: {
      display: 'inline-flex',
      gap: theme.spacing(1),
      flexWrap: 'wrap',
      marginInlineStart: 10
    },
    chipNoClickable: {
        cursor: 'none',
    },
    chipWinner: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
    },
    chipProno: {
      cursor: 'pointer',
      borderRadius: '50%',
      // border: `2px solid ${theme.palette.primary.main}`,
      // padding: theme.spacing(1),
      '&.selected': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    chipCovering: {
      cursor: 'pointer',
      borderRadius: '8%',
      // border: `2px solid ${theme.palette.primary.main}`,
      // padding: theme.spacing(1),
      '&.selected': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    resultsContainer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(3),
    },
    chipResult: {
      // backgroundColor: theme.palette.primary.main,
      // color: theme.palette.common.white,
    },
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
})