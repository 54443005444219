import React, { useEffect, useState } from 'react';
import {
    Container, Typography, Grid, Box, Chip, Paper,
    Accordion, AccordionSummary, AccordionDetails, Divider, Button, Skeleton, IconButton, Checkbox
} from '@mui/material';

import axios from 'axios';

import storage from './storage';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { BASE_URL } from '../config';
import { Favorite, PlayArrow } from '@mui/icons-material';
import PlaceHolder from './LoadingPlaceHolder';
import CriteriaSummary from './CriteriaSummary';
import CriteriaPaper from './CriteriaPaper';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(3),
  },
  criteriaGroup: {
    marginBottom: theme.spacing(3),
  },
  criteriaItem: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  parityIcon: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
  },
  shapeIcons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  frequencyText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

const label = { inputProps: { 'aria-label': 'Filtre les Favoris' } };

const Statistics = ({game, lazy = false, lastRecentCount,  data = [], onMoreClick  }) => {
  const classes = useStyles();

  const [ evaluationData, setEvaluationData ] = useState({});
  const [ evaluationLoading,  setEvaluationLoading ] = useState({});

  const [ favorite, setFavorite ] = useState({});
  const [ groupedCriteria, setGroupedCriteria ] = useState(null)

  const [ filterFavorite, setFilterFavorite ] = useState(false);

  useEffect(() => {
    setGroupedCriteria(groupCriteria(data));
  }, []);

  const parityExists = (parityKey) => {
    return Object.keys(groupedCriteria[parityKey] || {}).filter(aKey => aKey.length > 0).length > 0;
  }

  const shapeExists = (parityKey, shapeKey) => {
    return parityExists(parityKey) && (groupedCriteria[parityKey][shapeKey]?.length || 0) > 0;
  }

  const extractParity = (val) => { 
    const parity = val.match(/(\d)/g);
    return [ parseInt(parity[0]), parseInt(parity[1]) ]
  }

  // Function to group criteria by parity and shapes
  const groupCriteria = (criteriaArray) => {
    const groupedCriteria = {};
    criteriaArray.forEach((criteria) => {
      const { criteria : { parity = [], shape = [], frequency = [] },  count = 0 } = criteria;

      // Group by parity
      const parityKey = `${parity[0]}E/${parity[1]}O`;
      const shapeKey = shape.filter(aShape => aShape > 0).sort((a, b) => a - b).join("-");
      if (!groupedCriteria[parityKey]) {
        groupedCriteria[parityKey] = { [shapeKey] : [] };
      } 

      if(!groupedCriteria[parityKey][shapeKey]){
        groupedCriteria[parityKey][shapeKey] = [];
      }

      groupedCriteria[parityKey][shapeKey].push({ shape, frequency, count });

      // You can further customize the grouping logic here.
    });
    
    return groupedCriteria;
  };

  const buildCriteriaKey = (aParityKey, aCriteria) => {
    return `${game}_${aParityKey}_${JSON.stringify({ ...aCriteria, count: 0 })}`;
  }

  const isFavorite = (aParityKey, aCriteria) => {
    const key = buildCriteriaKey(aParityKey, aCriteria);
    return (storage.existsFavoriteCriteria(key) || favorite[key]);
  }

  const favoriteColor = (aParityKey, aCriteria) => {
    return isFavorite(aParityKey, aCriteria) ? 'disabled' : 'primary';
  }

  const handleClickFavoriteCriteria = (aParityKey, aCriteria) => {
    const key = buildCriteriaKey(aParityKey, aCriteria);
    const existe = storage.existsFavoriteCriteria(key);
    storage.addToFavoriteCriterias(
        key, 
        existe ? null : {...aCriteria, parity: extractParity(aParityKey)}
    );
    setFavorite({ ...favorite, [key] : !existe })
  }

  const handleClickUseCriteria = ({ parity, shape, frequency }) =>{
    let url = new URL(window.location.href);
    url.searchParams.append("parity", parity.join(","));
    url.searchParams.append("shape", shape.join(","));
    url.searchParams.append("frequency", frequency.join(","));
    window.open(url, '_blank').focus();
  } 

  const handleAccordionChange = (parityKey, parity) => (event, isExpanded) => {
    if(!lazy || !isExpanded || parityExists(parityKey)) return;

    axios({
        method: "POST",
        url : `${BASE_URL}/`,
        data: axios.toFormData({
          action: "GET_LESS_SELECTIVE_CRITERIA",
          count: lastRecentCount,
          type: game,
          details_of_criteria: JSON.stringify({
            parity
          }),
          criteria: 'PSF'
        }),
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
        // const values = (response?.data?.data || []).map(({ criteria, count }) => ({ ...criteria, count }))
        const theResult = groupCriteria(response?.data?.data || [])
        if(groupedCriteria) setGroupedCriteria({ ...groupedCriteria, [parityKey] : theResult[parityKey] } );
        else setGroupedCriteria({ [parityKey] : theResult[parityKey] } );
      }).catch(error => {
        if(groupedCriteria) setGroupedCriteria( { ...groupedCriteria, [parityKey]: null } );
        else setGroupedCriteria({ [parityKey] : null } );
      })
  }

  // const groupedCriteria = groupCriteria(data);

  const handleTesterClick = (parity, theCriteria) => {

    setEvaluationLoading({ ...evaluationLoading, [parity]: true });
    axios({
      method: "POST",
      url : `${BASE_URL}/`,
      data: axios.toFormData({
        action: "GET_CRITERIA_EVALUATION",
        count: lastRecentCount,
        type: game,
        criteria: JSON.stringify(theCriteria)
      }),
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
        setEvaluationLoading({ ...evaluationLoading, [parity]: false });
        setEvaluationData({ ...evaluationData, [parity] : response?.data } );
        storage.addToEvaluationResult(parity, response?.data);
    }).catch(error => {
        setEvaluationLoading({ ...evaluationLoading, [parity]: false });
        setEvaluationData( { ...evaluationData, [parity]: null } );
        storage.addToEvaluationResult(parity, null);
    })

  }

  return (
    <Container className={classes.container}>
      <Box display="flex" flexDirection="row">
        <Checkbox {...label} onChange={() => setFilterFavorite(!filterFavorite)} /> 
        <Typography variant='span' paddingTop={1.5}>Filter les Favoris</Typography>
      </Box> 
      {!groupedCriteria ? <PlaceHolder />  : Object.keys(groupedCriteria).map((parityKey, a_idx) => (
        <Accordion key={`accordion_${a_idx}_${parityKey}`} onChange={handleAccordionChange(parityKey, extractParity(parityKey))}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
            
                <CriteriaSummary 
                    evenCount={extractParity(parityKey)[0]}
                    oddCount={extractParity(parityKey)[1]}
                    count={Object.keys(groupedCriteria[parityKey]).map(aShape => (groupedCriteria[parityKey][aShape] || []).length).reduce((a, b) => a + b, 0)}
                />
        </AccordionSummary>

        <AccordionDetails  key={`accordion_details_${a_idx}_${parityKey}`}>
            {(!parityExists(parityKey)) ? <PlaceHolder />  : <Grid container md={12} lg={12}  key={`accordion_details_grid_${a_idx}_${parityKey}`} spacing={2}>
                    
                {Object.keys(groupedCriteria[parityKey]).map((shapeKey, as_idx) => (
                        <Container className={classes.container}>
                            <Accordion key={`accordion_${a_idx}_${parityKey}_${as_idx}_${shapeKey}`} /* onChange={handleAccordionChange(parityKey, extractParity(parityKey))}*/>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content" id="panel1a-header">
                                
                                    <CriteriaSummary 
                                        evenCount={extractParity(parityKey)[0]}
                                        oddCount={extractParity(parityKey)[1]}
                                        shape={shapeKey}
                                        count={groupedCriteria[parityKey]?.[shapeKey]?.length}
                                    />
                                </AccordionSummary>

                                <AccordionDetails  key={`accordion_details_shape_${a_idx}_${parityKey}_${as_idx}_${shapeKey}`}>

                                    <Grid container md={12} lg={12}  key={`accordion_details_grid_${a_idx}_${parityKey}_${as_idx}_${shapeKey}`} spacing={2}>

                                    {groupedCriteria[parityKey][shapeKey].filter(aCriteria => !filterFavorite || !isFavorite(parityKey, aCriteria))
                                        .map((criteria, ps_idx) => (
                                            <CriteriaPaper
                                                key={`CriteriaPaper_${ps_idx}_${shapeKey}_${parityKey}`} 
                                                idx={ps_idx}
                                                parityKey={parityKey}
                                                shapeKey={shapeKey}
                                                criteria={criteria}
                                                evaluationData={evaluationData} 
                                                evaluationLoading={evaluationLoading}
                                                extractParity={extractParity}
                                                favoriteColor={favoriteColor} 
                                                onMoreClick={onMoreClick} 
                                                handleTesterClick={handleTesterClick} 
                                                handleClickFavoriteCriteria={handleClickFavoriteCriteria} 
                                                handleClickUseCriteria={handleClickUseCriteria} 
                                            />
                                        ))}

                                    </Grid>

                                </AccordionDetails>

                            </Accordion>
                        </Container>
                ))}
            </Grid>}
        </AccordionDetails>

      </Accordion>
      ))}
    </Container>
  );
};

export default Statistics;
