import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Pronos from './PronosCompo';
import Stats from './Stats';

const getParamFromUrl = (props, property) => {
	const search = props.location.search;
  const params = new URLSearchParams(search);
	return params.get(property);
};

const getArrayParamFromUrl = (props, property) => {
	const search = props.location.search;
  const params = new URLSearchParams(search);
	const value = params.get(property);
  return value ? value.split(",").map(aPart => parseInt(aPart)) : undefined
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const criteria = () => {
    let url = new URL(window.location.href);

    const parity = (url.searchParams.get("parity") || "").split(",").filter(aPart => aPart !== "").map(aPart => parseInt(aPart));
    const shape = (url.searchParams.get("shape")  || "").split(",").filter(aPart => aPart !== "").map(aPart => parseInt(aPart));
    const frequency = (url.searchParams.get("frequency") || "").split(",").filter(aPart => aPart !== "").map(aPart => parseInt(aPart));

    return (parity && parity.length > 0) && (shape && shape.length > 0) && (frequency && frequency.length > 0) && { parity, shape, frequency };
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered variant="fullWidth">
          <Tab label="LOTO" />
          <Tab label="EUROMILLION" />
          {/* <Tab label="STATS" /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Pronos type='LT' 
          lastRecentCount={getParamFromUrl(props, "recent") || 10} 
          frequenciesCount={getParamFromUrl(props, "frequency") || 15} 
          criteria={criteria()}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Pronos type='EU'
         lastRecentCount={getParamFromUrl(props, "recent") ||10} 
         frequenciesCount={getParamFromUrl(props, "frequency") || 15} 
         criteria={criteria()}
        />
      </CustomTabPanel>
      {/*<CustomTabPanel value={value} index={2}>
        <Stats 
          lastRecentCount={getParamFromUrl(props, "recent") ||10} 
          frequenciesCount={getParamFromUrl(props, "frequency") || 15}  
        />
  </CustomTabPanel> */}
    </Box>
  );
}