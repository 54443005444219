import React from 'react';

import { Skeleton, Stack } from '@mui/material';

const PlaceHolder = () => <Stack spacing={1} style={{ width: "100%" }} gap={2}>
<Skeleton animation="wave" variant="text" style={{ fontSize: '3rem', width: "100%" }} />
<Skeleton animation="wave" variant="rectangular" style={{ fontSize: '2rem', width: "100%" }} />
<Skeleton animation="wave" variant="rounded" style={{ fontSize: '1rem', width: "100%" }} />
</Stack>;


export default PlaceHolder;