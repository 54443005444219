const FAVORITE_CRITERIA_STORE = "FAVORITE_CRITERIA_STORE";

const EVALUATION_RESULT_STORE = "EVALUATION_RESULT_STORE";

const favoriteCriteria = () => { 
    let values = localStorage.getItem(FAVORITE_CRITERIA_STORE);
    if(!values) values = {};
    else values = JSON.parse(values);

    return values;
}

const evaluationResult = () => {
    let values = localStorage.getItem(EVALUATION_RESULT_STORE);
    if(!values) values = {};
    else values = JSON.parse(values);

    return values;
}

const addToEvaluationResult = (key, result) => {

    let results = evaluationResult();

    if(result) results[key] = result;
    else delete results[key];

    localStorage.setItem(EVALUATION_RESULT_STORE, JSON.stringify(results));
}

const getEvaluationResult = (key) => {
    return evaluationResult()[key];
}

const addToFavoriteCriterias = (criteriaKey, aCriteria) => {
    let favoriteCriterias = favoriteCriteria();

    if(aCriteria) favoriteCriterias[criteriaKey] = aCriteria;
    else delete favoriteCriterias[criteriaKey];

    localStorage.setItem(FAVORITE_CRITERIA_STORE, JSON.stringify(favoriteCriterias));
}

const removeFromFavoriteCriterias = (criteriaKey) => {
    addToFavoriteCriterias(criteriaKey);
}

const existsFavoriteCriteria = (criteriaKey) => {
    return !!favoriteCriteria()[criteriaKey];
}

const getFavoriteCriterias = (game) => {
    const criterias = favoriteCriteria();
    return Object.keys(criterias)
        .filter(aKey => aKey.startsWith(game))
        .map(aKey => criterias[aKey])
        .map(aCriteria => { 
            const count = aCriteria?.count;
            delete aCriteria.count
            return { criteria : aCriteria, count }
        })
}


export default {
    addToFavoriteCriterias,
    existsFavoriteCriteria,
    removeFromFavoriteCriterias,
    getFavoriteCriterias,

    addToEvaluationResult,
    getEvaluationResult
}