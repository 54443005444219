import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SaaSPage.css';

const SaaSPage = () => {
  return (
    <div className="saas-page">
      <header className="header">
        <div className="logo">TIRAGE MALIN</div>
        <nav>
          {/*<a href="#contact" className="contact-link">Contact</a> */}
        </nav>
      </header>
      
      <section className="hero">
        <h1>L'INTELIGENCE ARTIFICIEL au service des <br/>PARIS SPORTIFS</h1>
        <p>La seule façon de battre un système, c'est d'en utiliser un autre contre lui. Essayer nos outils totalement gratuit.</p>
        <div className="cta-buttons">
          <Link to="/pronostiker" className="cta-button">CHATBOT PARIS SPORTIF</Link>
          <Link to="/pronos" className="cta-button-secondary">LOTO / EUROMILLION</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool tool1" id="tool1">
          <h2>CHATBOT PARIS SPORTIFS</h2>
          <p>Maximisez vos gains avec notre agent IA de conseil en paris sportifs. Essayez l'IA maintenant et pariez plus intelligemment !.</p>
          <Link to="/pronostiker" className="cta-button">ESSAYER GRATUITEMENT !</Link>
        </div>
        <div className="tool tool2" id="tool2">
          <h2>LOTO / EUROMILLION</h2>
          <p>Boostez vos chances de gagner au Loto et à l'Euromillion de la FDJ "GRATUITEMENT"</p>
          <Link to="/pronos" className="cta-button-secondary">ESSAYER GRATUITEMENT !</Link>
        </div>
      </section>

      {/*<section className="signup-section" id="signup">
        <h2>Get Started Today!</h2>
        <p>Sign up for a free trial and take your betting strategy to the next level.</p>
        <form className="signup-form">
          <input type="email" placeholder="Enter your email" required />
          <button type="submit" className="cta-button">Sign Up</button>
        </form>
      </section>*/}

      <footer className="footer">
        <p>&copy; 2024 TIRAGEMALIN. All rights reserved.</p>
        <nav>
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms of Service</a>
        </nav>
      </footer>
    </div>
  );
}

export default SaaSPage;
