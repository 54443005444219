// src/App.js
import React from 'react';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import Home from './components/Home';
import Pronos from './components/Pronos';
import Stats from './components/Stats';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import FullWidthIframe from './components/FullWidthIframe';
import LandingPage from './components/LandingPage';
import SaaSPage from './components/SaaSPage';
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_wrInOOvff5eJfXJ7-xIzNyFRZ7hIYO0",
  authDomain: "tiragemalin.firebaseapp.com",
  projectId: "tiragemalin",
  storageBucket: "tiragemalin.appspot.com",
  messagingSenderId: "192889760683",
  appId: "1:192889760683:web:d39f4689088f73f0f880ee",
  measurementId: "G-Y936N0KWYF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={SaaSPage} />
        <Route path="/pronos" component={Pronos} />
        <Route path="/stats" component={Stats} />
        <Route path="/pronostiker" render={() => <FullWidthIframe name="Pronostiker" url="https://seberthe-pronostiker.hf.space/" />} />
        <Redirect from="/" to="/" />
      </Switch>
    </HashRouter>
  )
}

export default App;
