import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

import './ValueSelector.css';

const ValueSelector = ({ data, onSelect, xPosition = 'bottom', maintainAspectRatio = false,
        title = "Fréquence des tirages précédents, de droite à gauche" }) => {
  const [selectedRange, setSelectedRange] = useState([data.labels[0], data.labels[data.labels.length - 1]]);
  
  const handleBarClick = (elems) => {
    if (elems.length > 0) {
      const selectedValue = data.labels[elems[0].index];
      setSelectedRange([selectedValue, selectedValue]);
      onSelect(selectedValue);
    }
  };

  const handleHandleDrag = (event, handleIndex) => {
    const newValue = data.labels[handleIndex];
    setSelectedRange((prevRange) => {
      const updatedRange = [...prevRange];
      updatedRange[handleIndex] = newValue;
      onSelect(updatedRange);
      return updatedRange;
    });
  };

  const options = {
    maintainAspectRatio: maintainAspectRatio,
    scales: {
      x: {
        type: 'linear',
        position: xPosition,
        beginAtZero: true,
      },
      y: {
        beginAtZero: true
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    onClick: handleBarClick,
  };

  return (
    <div>
      <Bar
        data={{
          labels: data.labels,
          datasets: [
            {
              label: title,
              data: data.values,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        }}
        options={options}
      />
      <div>
      </div>
      <div className="handle">
        <div
          className="handle-circle"
          draggable="true"
          onDrag={(e) => handleHandleDrag(e, 0)}
        ></div>
        <div
          className="handle-circle"
          draggable="true"
          onDrag={(e) => handleHandleDrag(e, 1)}
        ></div>
      </div>
    </div>
  );
};

export default ValueSelector;
