import React from "react";

import {     
    Container, Typography, Grid, Box, Chip, Paper,
    Accordion, AccordionSummary, AccordionDetails, Divider, Button, Skeleton, IconButton, Checkbox 
} from "@mui/material";
import { makeStyles } from '@mui/styles';

import { Favorite, PlayArrow } from '@mui/icons-material';

import storage from "./storage";

const useStyles = makeStyles((theme) => ({
    container: {
      paddingTop: theme.spacing(4),
    },
    sectionTitle: {
      marginBottom: theme.spacing(3),
    },
    criteriaGroup: {
      marginBottom: theme.spacing(3),
    },
    criteriaItem: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    parityIcon: {
      fontSize: '2rem',
      color: theme.palette.primary.main,
    },
    shapeIcons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    frequencyText: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  }));


const CriteriaPaper = ({ 
    idx, parityKey, shapeKey, criteria, evaluationData, evaluationLoading, extractParity, 
    favoriteColor, onMoreClick, handleTesterClick, handleClickFavoriteCriteria, handleClickUseCriteria 
}) => {

    const classes = useStyles();

    const buildKey = () => {
        const data = evaluationData()
        Object.keys(data).map(aKey => ({ "winner_tirage_count": data[aKey]["winner_tirage_count"], "rank_tirages":{"1": data[aKey]["rank_tirages"]["1"].length,"2": data[aKey]["rank_tirages"]["2"].length, "3": data[aKey]["rank_tirages"]["3"].length, "4": data[aKey]["rank_tirages"]["4"].length, "5" : data[aKey]["rank_tirages"]["5"].length }}))
        return `parity_key_${idx}_${parityKey}_${shapeKey}`;   
    }

    const getEvaluationData = () => {
        console.log("getEvaluationData. = ", evaluationData[buildKey()] || storage.getEvaluationResult(buildKey()))
        return evaluationData[buildKey()] || storage.getEvaluationResult(buildKey());
    } 

    return <Grid item xs={12} md={3} lg={4}  key={`accordion_details_grid_sub_grid_${idx}_${shapeKey}_${parityKey}`}>
    <Paper>
        <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            className={classes.criteriaItem}
            p={2}
        >
            <Grid container md={12} lg={12} spacing={1}>

                <Grid item xs={12} md={5} lg={5}>
                    <Typography mt={0.5}>FORME : <Divider color="primary" /></Typography>
                </Grid>

                <Grid item xs={12} md={7} lg={7}>
                    <Box display="flex" flexDirection="row" gap={1}>
                        <Chip
                            label={`${criteria.shape[0]}`}
                            icon={<i className={`fas fa-square ${classes.parityIcon}`} />}
                            variant="filled"
                            color="primary"
                        />
                        <Chip
                            label={`${criteria.shape[1]}`}
                            icon={<i className={`fas fa-circle ${classes.parityIcon}`} />}
                            variant="filled"
                            color="secondary"
                        />
                        <Chip
                            label={`${criteria.shape[2]}`}
                            icon={<i className={`fas fa-triangle ${classes.parityIcon}`} />}
                            variant="filled"
                            color="error"
                        />
                        <Chip
                            label={`${criteria.shape[3]}`}
                            icon={<i className={`fas fa-star ${classes.parityIcon}`} />}
                            variant="filled"
                            color="success"
                        />
                        <Chip
                            label={`${criteria.shape[4]}`}
                            icon={<i className={`fas fa-heart ${classes.parityIcon}`} />}
                            variant="filled"
                            color="warning"
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                    <Typography>FREQUENCE : <Divider color="primary" /></Typography>
                </Grid>

                <Grid item xs={12} md={7} lg={7}>
                    <Typography fontWeight="bold">{criteria.frequency[0]} - {criteria.frequency[1]}</Typography>
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                    <Typography>TIRAGES : <Divider color="primary" /></Typography>
                </Grid>

                <Grid item xs={12} md={7} lg={7}>
                    <Typography fontWeight="bold">{criteria.count}</Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box display="flex" flexDirection="row" gap={1}>
                        {onMoreClick && <Button variant="contained" size="small" color="info" onClick={() => onMoreClick({...criteria, parity: extractParity(parityKey)})}>DETAILS</Button>}
                        <Button variant="contained" size="small" color="secondary" onClick={() => handleTesterClick(buildKey(), {...criteria, parity: extractParity(parityKey)})}>TESTER</Button>
                        <IconButton aria-label="favorite" onClick={() => handleClickFavoriteCriteria(parityKey, criteria)}>
                            <Favorite color={favoriteColor(parityKey, criteria)} />
                        </IconButton>
                        <IconButton aria-label="favorite" onClick={() => handleClickUseCriteria({...criteria, parity: extractParity(parityKey)})}>
                            <PlayArrow color="primary" />
                        </IconButton>
                    </Box>
                </Grid>

                {(getEvaluationData() || evaluationLoading[buildKey()]) && <Grid item xs={12} md={12} lg={12}>
                    {evaluationLoading[buildKey()] 
                    ? <Skeleton animation="wave" variant="text" style={{ fontSize: '3rem', width: "100%" }} />
                    : (
                    <>
                        <Divider width="100%"/>
                        <Box display="flex" flexDirection="row" gap={1}>
                            <Typography variant='caption'>VI: {getEvaluationData()?.winner_tirage_count}</Typography>
                            <Typography variant='caption'>R1: {getEvaluationData()?.rank_tirages["5"]}</Typography>
                            <Typography variant='caption'>R2: {getEvaluationData()?.rank_tirages["4"]}</Typography>
                            <Typography variant='caption'>R3: {getEvaluationData()?.rank_tirages["3"]}</Typography>
                            <Typography variant='caption'>R4: {getEvaluationData()?.rank_tirages["2"]}</Typography>
                            <Typography variant='caption'>R5: {getEvaluationData()?.rank_tirages["1"]}</Typography>
                        </Box>
                    </>)}
                </Grid>}

            </Grid>
        </Box>
    </Paper>
</Grid>;

}

export default CriteriaPaper;