// components/Stats.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  Container,
  Typography,
  Divider, ToggleButton, Skeleton, Stack, 
  Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { BASE_URL } from '../config';

import styles from './usePronosStyle';
import CriteriaStatsFilter from './CriteriaStatsFilter';
import Statistics from './Statistics';
import PlaceHolder from './LoadingPlaceHolder';

const useStyles = makeStyles(styles);

function Stats({ type = 'LT', lazy = false, lastRecentCount = 10, frequenciesCount = 15, data = undefined }) {

  const classes = useStyles();

  const [ game, setGame ] = useState(type);
  const [ criteria, setCriteria ] = useState("PSF");
  // const [ criteriaStats, setCriteriaStats] = useState(null);

  const [ lessSelectiveCriteria, setLessSelectiveCriteria ] = useState(data);
  const [ loadingCriteria, setLoadingCriteria ] = useState(false);
  
  const [ detailsDialogOpen, setDetailsDialogOpen ] = useState(false);
  const [ detailsDialogLoading, setDetailsDialogLoading ] = useState(false);
  const [ detailsLessSelectiveCriteria, setDetailsLessSelectiveCriteria ] = useState(null);

  useEffect(() => {
    if(!data) fetchLessSelectiveCriteriaStats();
  }, []);

  function handleGameChange(newGame){
    setGame(newGame);
    fetchLessSelectiveCriteriaStats();
  }

  function fetchLessSelectiveCriteriaStats(){
    setLoadingCriteria(true);
    axios({
      method: "POST",
      url : `${BASE_URL}/`,
      data: axios.toFormData({
        action: "GET_LESS_SELECTIVE_CRITERIA",
        count: lastRecentCount,
        type: game,
        criteria
      }),
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
      setLoadingCriteria(false);
      setLessSelectiveCriteria(response?.data);
    }).catch(error => {
      setLoadingCriteria(false);
      setLessSelectiveCriteria(null);
    })

  }

  const handleDetailsClose = () => setDetailsDialogOpen(false);

  const handleDetails = (details_of_criteria) => {
    setDetailsDialogOpen(true);
    setDetailsDialogLoading(true);

    axios({
      method: "POST",
      url : `${BASE_URL}/`,
      data: axios.toFormData({
        action: "GET_LESS_SELECTIVE_CRITERIA",
        count: lastRecentCount,
        type: game,
        details_of_criteria: JSON.stringify(details_of_criteria),
        criteria
      }),
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
      setDetailsDialogLoading(false);
      setDetailsLessSelectiveCriteria(response?.data);
    }).catch(error => {
      setDetailsDialogLoading(false);
      setDetailsLessSelectiveCriteria(null);
    })
  }

  return (
    <Container className={classes.container}>

      <Typography variant="h5" component={'span'} gutterBottom>
        STATISTIQUES 
      </Typography>

      {/* <div className={classes.filters}>
        <div className={classes.filterGroup}>

          <Typography component={'span'} className={classes.filterTitle}>
            JEU
            <Divider color="primary" />
          </Typography>

          <div className={classes.chipContainer}>
            <ToggleButton value="LT" selected={game === "LT"} onChange={(e, value) => handleGameChange("LT")} aria-label="LT" color='primary'>
              LOTO
            </ToggleButton>
            <ToggleButton value="EU" selected={game === "EU"} onChange={(e, value) => handleGameChange("EU")} aria-label="EU" color='primary'>
              EUROMILLION
            </ToggleButton>
          </div>

        </div>
  </div> */}

      <div className={classes.filters}>
        {/* <div className={classes.filterGroup}>
          <Typography component={'span'} className={classes.filterTitle}>
            PARITE - FORME - FREQUENCE
            <Divider color="primary" />
          </Typography>

          <div className={classes.chipContainer}>

            <CriteriaStatsFilter onFilterChange={theCriteria => {
              setCriteria(theCriteria);
            }} />

          </div>
          </div> */}

        <div className={classes.filterGroup}>
          <Typography component={'span'} className={classes.filterTitle}>
              SELECTIVITE CRITERE RECHERCHE 
              <Divider color="primary" />
          </Typography>

          <div className={classes.chipContainer}  style={{ fontSize: '1rem', width: "100%" }}>
            {
              loadingCriteria 
              ? <PlaceHolder />
              : <Statistics 
                  game={game}
                  lazy
                  lastRecentCount={lastRecentCount}
                  data={lessSelectiveCriteria?.data} 
                  onMoreClick={criteria => handleDetails(criteria)}
                />
            }
            
            <Dialog open={detailsDialogOpen} 
                onClose={handleDetailsClose} 
                fullWidth={true}
                maxWidth="lg"
              >
              <DialogTitle>Details Critère</DialogTitle>
              <DialogContent>
                {
                  detailsDialogLoading 
                  ? <PlaceHolder />
                  : <Statistics 
                      game={game}
                      lastRecentCount={lastRecentCount}
                      data={detailsLessSelectiveCriteria?.data} 
                    />
                }
              </DialogContent>
              <DialogActions>
                <Button variant='contained' color='error' onClick={handleDetailsClose}>FERMER</Button>
              </DialogActions>
            </Dialog>
          </div>
          
        </div>

      </div>
    </Container>
  );
}

export default Stats;
