// components/Pronos.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Slider,
  Chip,
  Divider, Backdrop, CircularProgress, Box, Grid, TextField
} from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import { makeStyles } from '@mui/styles';

import ValueSelector from './ValueSelector';

import LazyList from './LazyList';

import { LoadingButton } from '@mui/lab';

import { arraysHaveSameElements } from '../utils';

import { BASE_URL } from '../config';

import styles from './usePronosStyle';
import FullScreenDialog from './FullScreenDialog';
import Stats from './Stats';
import storage from './storage';
import dataParity from './dataParity';

const pageSize = 500;

const Cost = {
  'LT': {
    'unitaire': 2.20,
    'cost_7': 46.20,
    'cost_10': undefined,
    'cost_15': undefined,
    'cost_18': undefined
  },
  'EU': {
    'unitaire': 2.50,
    'cost_7': 52.50,
    'cost_10': 630.0,
    'cost_15': undefined,
    'cost_18': undefined
  }
}

const useStyles = makeStyles(styles);

function CoveringDesignView({ type, pool_size, warranty, tirages, raised, ...other }) {
  const cout = Cost[type][`cost_${pool_size}`]
  return (
    <Card onClick={other.onClick} raised={raised}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" color="primary">
            {`REDUCTEUR DE ${pool_size} NUMERO`}
          </Typography>
          <ul>
            <li>
              <Typography variant="body2" color="text.secondary">
                {`JOUER ${pool_size} NUMERO`}
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                {`GARANTIR AU MOINS 1 TICKET AVEC ${warranty} BON SI ${other.if} PARMI LES ${pool_size}`}
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                {`COÛT ${tirages} TICKETS`} OÙ <Typography variant='caption' color='primary'>{`${(Cost[type].unitaire * tirages).toFixed(2)} €`}</Typography> - (FDJ = <Typography variant='caption' color='error'>{`${cout !== undefined ? `${cout.toFixed(2)} €` : 'Non Autorisée sans le REDUCTEUR' }`}</Typography>)
              </Typography>
            </li>
            {!!other.libelle && <li>
                <Typography variant="body2" color="warming">
                  {`${other.libelle}`}
                </Typography>
              </li>
            }
          </ul>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Pronos( { type = 'LT', lastRecentCount = 10, frequenciesCount = 15, criteria = undefined } ) {
  const classes = useStyles();

  // State for filter criteria
  const [inclusiveNumbers, setInclusiveNumbers] = useState([]);
  const [exclusiveNumbers, setExclusiveNumbers] = useState([]);
  const [frequencyRange, setFrequencyRange] = useState([0, 100]);

  // State for selected chips
  const [selectedCovering, setSelectedCovering] = useState(null);
  const [selectedOddChip, setSelectedOddChip] = useState(null);
  const [selectedEvenChip, setSelectedEvenChip] = useState(null);
  const [selectedShapeChips, setSelectedShapeChips] = useState([null, null, null, null, null]);

  const [searchResults, setSearchResults] = useState([]);
  const [ searchResultFilter, setSearchResultFilter ] = useState("")
  const [searchInProgress, setSearchInProgress] = useState(false); 
  const [ recentStats, setRecentStats ] = useState(null);
  const [ weightStats, setWeightStats ] = useState(null);
  const [ coverings, setCoverings ] = useState([]);
  const [ frequencyData, setFrequencyData ] = useState({
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    values: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  })
  const [ firstInit, setFirstInit ] = useState(false);
  const [ loadableSearchResult, setLoadableSearchResult ] = useState([]);

  const handleValueSelect = (selectedValue) => {
    // console.log(`Selected value: ${selectedValue}`);
    // You can update your application's logic here with the selected value.
  };

  useEffect(() => {
    // window.location.reload();
    fetchRecents();
    fetchWeights();
    fetchCoverings();
  }, []);
  // Function to handle chip selection
  const handleChipClick = (index, type, rootIndex) => {
    if (type === 'odd') {
      setSelectedOddChip(index === selectedOddChip ? null : index);
    } else if (type === 'even') {
      setSelectedEvenChip(index === selectedEvenChip ? null : index);
    } else if (type === 'shape') {
      const updatedChips = [...selectedShapeChips];
      updatedChips[rootIndex] = index === selectedShapeChips[rootIndex] ? null : index;
      setSelectedShapeChips(updatedChips);
    }
  };

  const handleSearchFilterChange = (event) => {
    console.log("Change Evt : ", event.target.value)
    setSearchResultFilter(event.target.value)

  }

  const searchTextToArray = () => {
    let value = searchResultFilter || ""
    value = value.replaceAll('  ', ',')
    return value.replaceAll(' ', ',').split(',').filter(v => !!v).map(v => parseInt(v))
  }

  const isInFilter = (aTirages = []) => {
    // console.log(aTirages)
    const filterNumbers = searchTextToArray()
    if (filterNumbers.length > 0){
      const iTirages = aTirages.map(v => parseInt(v)).sort((a, b) => a - b)
      return filterNumbers.map(v => parseInt(v)).sort((a, b) => a - b).some(v => iTirages.includes(v));
    }
    return true;
  }

  const isInAFilter = (aTirage = 0) => {
    const filterNumbers = searchTextToArray()
    // console.log(filterNumbers)
    if (filterNumbers.length > 0){
      return filterNumbers.map(v => parseInt(v)).includes(aTirage)
    }
    return false;
  }

  const handleSelectCovering = (code) => {
    setSelectedCovering(selectedCovering == code ? null : code)
  } 

  const fetchWeights = () => {
    axios({
        method: "POST",
        url : `${BASE_URL}/`,
        data: axios.toFormData({
          action: "GET_WEIGHTS",
          type
        }),
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
        // console.log("Response ", response)
        const results = response?.data ?? null;
        setWeightStats(results);
        const weights_ = (results?.statistics?.values || []);
        let sampleWeight = [];
        let index = 0;
        // console.log("weights_ = ", weights_)
        weights_.forEach(weight_ => {
            if(++index < parseInt(frequenciesCount)){
                sampleWeight.push(weight_);
            }
        });
        // console.log("sampleWeight = ", sampleWeight)
        setFrequencyData({
            labels: sampleWeight.map((aValue, index) => (recentStats?.tirages && recentStats.tirages.length > 0 && recentStats?.tirages[index] || aValue)  + ""),
            values: sampleWeight
        })
    })
    .catch(error => {
        // handle error
        // console.log("Error  ", error)
        setWeightStats(null)
    });
  }

  const fetchRecents = () => {
    axios({
      method: "POST",
      url : `${BASE_URL}/`,
      data: axios.toFormData({
        action: "GET_RECENTS",
        count: 15,// lastRecentCount,
        type
      }),
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
        // ("ResponseREST ", response)
        const results = response?.data ?? null;
        setRecentStats(results);
        if(results && !criteria){
            const { even_count, odd_count } = results?.odd_and_even_ness[0];
            const shape = results?.shapes[0];
            const frequency = results?.frequencies[0]; 
            setSelectedOddChip(odd_count);
            setSelectedEvenChip(even_count);
            setSelectedShapeChips(shape);
            setFrequencyRange([frequency, frequency]);
        } else if(criteria){
          const { parity, shape, frequency } = criteria;
          setSelectedOddChip(parity[1]);
          setSelectedEvenChip(parity[0]);
          setSelectedShapeChips(shape);
          setFrequencyRange(frequency);
        }
    })
    .catch(error => {
        // handle error
        // console.log("Error  ", error)
        setRecentStats(null)
    });
  }

  const fetchCoverings = () => {
    axios({
      method: "POST",
      url : `${BASE_URL}/`,
      data: axios.toFormData({
        action: "GET_COVERING",
      }),
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
        // ("ResponseREST ", response)
        const results = response?.data?.data ?? [];
        // console.log('Covering ', results)
        setCoverings(results);
    })
    .catch(error => {
        // handle error
        // console.log("Error  ", error)
        setCoverings([])
    });
  }

  // Function to handle the search button click
  function handleSearch() {
    // Implement the search logic here based on the filter criteria
    // Update the search results
    setSearchInProgress(true);
    let jsonData = {}
    if(selectedCovering){
      jsonData = {
        action: "GET_REDUCTEUR_NUMBERS",
        tirages: inclusiveNumbers.join(","),
        convering: selectedCovering,
        type
      }
    } else {
      const shape_ = (selectedShapeChips.map(aShape => aShape || 0) || [])
      jsonData = {
        action: "GET_BEST_NUMBERS",
        number_of_pair: selectedOddChip == 0 && selectedEvenChip == 0 ? -1 : selectedEvenChip,
        number_of_impair: selectedOddChip == 0 && selectedEvenChip == 0 ? -1 : selectedOddChip,
        shape: shape_.filter(aShape => aShape > 0).length == 0 ? "" : shape_.join(","),
        min_weight: frequencyRange[0],
        max_weight:  frequencyRange[1],
        numbers_to_exclude: exclusiveNumbers.join(","),
        numbers_to_include: inclusiveNumbers.join(","),
        type
      }
    }
    axios({
      method: "POST",
      url : `${BASE_URL}/`,
      data: axios.toFormData(jsonData),
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => {
        // console.log("Response ", response)
        let results = response?.data?.tirages ?? [];
        const lastResult = ((recentStats?.tirages && recentStats.tirages.length > 0 && recentStats?.tirages[0]) || []);
        results = results.sort((a, b) => arraysHaveSameElements(a, lastResult) ? -1 : (arraysHaveSameElements(b, lastResult) ? 1 : 0))
        setSearchResults(results);
        setSearchInProgress(false);

        const effectiveSize = results.length < pageSize ? results.length : pageSize;
        const items = [];
        for (let index = 0; index < effectiveSize; index++) {
          items.push(results[index]);
        }
        setLoadableSearchResult(items)
    })
    .catch(error => {
        // handle error
        // console.log("Error  ", error)
        setSearchInProgress(false);
    });
  };

  const fetchMoreItems = async (page) => {
    // Simulate fetching data from an API.
    const currentIndex = page * pageSize;
    console.log("CurrentIndex : ", currentIndex, " Page : ", page)
    if(currentIndex >= searchResults.length) {
      return new Promise((resolve) => {
         resolve([]);
      });
    }
    return new Promise((resolve) => {
      const newItems = [];
      for (let index = loadableSearchResult.length; index < loadableSearchResult.length + pageSize; index++) {
        if(index < searchResults.length){
          newItems.push(searchResults[index])
        }
      }
      setLoadableSearchResult((prevItems) => [...prevItems, ...newItems]);
      resolve(newItems);
    });
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" component={'span'} gutterBottom>
        GENERATEUR TIRAGES OPTIMISES - {type == 'LT' ? 'LOTO' : 'EUROMILLION'}  <Typography variant='caption' color='primary'>(Changez de tirage (Loto/Euromillion) en utilisant le menu ci-dessus.)</Typography>
      </Typography>
      {/*<Box mt={3} mb={3} display="flex" flexDirection="row" gap={1}>
        <FullScreenDialog 
          title={`Statistique '${type == 'LT' ? 'LOTO' : 'EUROMILLION'}'`}
          buttonText="Voir Statistiques Critères de Recherche"
          >
          <Stats
            type={type}
            lazy={true}
            lastRecentCount={lastRecentCount} 
            frequenciesCount={frequenciesCount}
            data={{ data : dataParity }}
          />
        </FullScreenDialog>

        <FullScreenDialog 
          title={`Statistique '${type == 'LT' ? 'LOTO' : 'EUROMILLION'}'`}
          buttonText="Voir les Critères Favoris"
          buttonColor="warning"
          >
          <Stats
            type={type}
            lastRecentCount={lastRecentCount} 
            frequenciesCount={frequenciesCount} 
            data={{ data : storage.getFavoriteCriterias(type) }}
          />
        </FullScreenDialog>
  </Box>*/}

      <Box mt={3} mb={3} display="flex" flexDirection="column" gap={1}>

        <Typography component={'span'} className={classes.filterTitle}>
            SELECTIONNER LE SYSTEMES REDUCTEURS <Typography variant='caption' color='primary'>(L'utilisation de SYSTEME REDUCTEUR vous permet de jouer plus de numero pour MOINS CHER.)</Typography>
            <Divider color="primary" />
        </Typography>

        <Grid container md={12} xs={12} spacing={2}>
            {coverings.map((value) => (
               <Grid item>
                  {/*<Chip
                    key={`covering_list_${value.code}`}
                    label={`JOUER ${value.pool_size} N° - ${value.warranty} GARANTI SI ${value.if} sur ${value.tirages} COMB.`}
                    color="primary"
                    className={`${selectedCovering === value.code ? 'selected' : ''} ${classes.chipCovering}`}
                    onClick={() => handleSelectCovering(value.code)}
            />*/}
                  <CoveringDesignView {...value} type={type} raised={value.code == selectedCovering} onClick={() => handleSelectCovering(value.code)}/>
                </Grid>
              ))}
          </Grid>
      </Box>
      <div className={classes.filters}>
        {/* Number of Odd Numbers Filter */}
        {!selectedCovering && <div className={classes.filterGroup}>
        <Typography component={'span'} className={classes.filterTitle}>
            SELECTIONNER LE NOMBRE DE CHIFFRES PAIRS / IMPAIRS 
            <Divider color="primary" />
          </Typography>
          <Typography variant='caption' color='primary'>* Les boules de couleur ORANGE symbolisent les données des precedents tirages du critère. Sélectionnez/Désélectionnez les boules BLEUES pour les nombres pairs ou impairs cibles</Typography>
          <div className={classes.chipContainer}>
            <Typography className={classes.filterTitle} style={{ width : 80 }}>PAIRS : </Typography>
            {[0, 1, 2, 3, 4, 5].map((value) => (
              <Chip
                key={`chiffre_paire_list_${value}`}
                label={value}
                disabled={(value + (selectedOddChip || 0) > 5) || (selectedOddChip !== null && value + selectedOddChip !== 5)}
                color="primary"
                className={`${
                  selectedEvenChip === value ? 'selected' : ''
                } ${classes.chipProno}`}
                onClick={() => handleChipClick(value, 'even')}
              />
            ))}
             {(recentStats?.odd_and_even_ness || []).map((aCouple, index) => (
                <Chip
                    key={`chiffre_paire_list_recent_${aCouple.even_count}_${index}`}
                    label={aCouple.even_count}
                    color="warning"
                    clickable={false}
                    className={`${classes.chipProno} ${classes.chipNoClickable}`}
                />
              ))}
          </div>
        </div>}

        {/* Number of Even Numbers Filter */}
        {!selectedCovering && <div className={classes.filterGroup}>
          <div className={classes.chipContainer}>
            <Typography className={classes.filterTitle} style={{ width : 80 }}>IMPAIRS : </Typography>
            {[0, 1, 2, 3, 4, 5].map((value) => (
              <Chip
                key={`chiffre_impair_list_${value}`}
                label={value}
                color="primary"
                disabled={(value + (selectedEvenChip || 0) > 5) || (selectedEvenChip !== null && value + selectedEvenChip !== 5)}
                className={`${
                  selectedOddChip === value ? 'selected' : ''
                } ${classes.chipProno}`}
                onClick={() => handleChipClick(value, 'odd')}
              />
            ))}
            {(recentStats?.odd_and_even_ness || []).map((aCouple, index) => (
                <Chip
                    key={`chiffre_impair_list_recent_${aCouple.odd_count}_${index}`}
                    label={aCouple.odd_count}
                    color="warning"
                    clickable={false}
                    className={`${classes.chipProno}`}
                />
              ))}
          </div>
        </div>}

        {/* Numbers Shape Filter */}
        {!selectedCovering && <div className={classes.filterGroup}>
          <Typography component={'span'} className={classes.filterTitle}>
          SELECTIONNER LA FORME CIBLE <Typography variant='caption' color='primary'>(Nombre de chiffre entre 1 - 9, 10 - 19, 20 - 29, 30 - 39 et 40 - 50)</Typography>
            <Divider color="primary" />
          </Typography>
          <Typography variant='caption' color='primary'>* Les boules de couleur ORANGE symbolisent les données des precedents tirages du critère. Sélectionnez/Désélectionnez les boules BLEUES pour composer la forme cible</Typography>
            {['01 → 09 : ', '10 → 19 : ', '20 → 29 : ', '30 → 39 : ', '40 → 50 : '].map((label, index) => (
                <div key={`la_forme_container_${label}_${index}`} className={classes.chipContainer}>
                  <Typography key={`la_forme_container_text_${label}_${index}`} style={{ width : 80 }}>{label}</Typography>
                  {[0, 1, 2, 3, 4, 5].map((value) => (
                    <Chip
                      key={`la_forme_value${value}`}
                      label={value}
                      color="primary"
                      disabled={selectedShapeChips[index] !== value && selectedShapeChips.filter(aShape => !!aShape).reduce((a, b) => a + b, 0) + value > 5}
                      className={`${
                        selectedShapeChips[index] === value ? 'selected' : ''
                      } ${classes.chipProno}`}
                      onClick={() => handleChipClick(value, 'shape', index)}
                    />
                  ))}
                  {(recentStats?.shapes || []).map((aShape, theShapeIndex) => (
                        <Chip
                            key={`recent_shape_${aShape[index]}_${index}_${theShapeIndex}`}
                            label={aShape[index]}
                            color="warning"
                            clickable={false}
                            className={`${classes.chipProno}`}
                        />
                    ))}
                </div>
            ))}
        </div>}

        {/* Numbers Frequency Filter */}
        {!selectedCovering && <div className={classes.filterGroup}>
          <Typography component={'span'} className={classes.filterTitle}>
          SELECTIONNER LA FREQUENCE DES NUMEROS CIBLE
            <Divider color="primary" />
          </Typography>
          <Typography variant='caption' color='primary'>* Utilisez le curseur pour ajuster la fréquence souhaitée des numéros cibles en fonction de l'historique des tirages. L'histogramme vous donne une vue visuelle.</Typography>
          <Slider
            value={frequencyRange}
            onChange={(event, newValue) => setFrequencyRange(newValue)}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value}`}
            disableSwap
            getAriaLabel={(index) => "Always visible"}
            min={0}
            max={1000}
            size='medium'
            style={{ marginTop : 24 }}
          />
          <ValueSelector data={frequencyData} onSelect={handleValueSelect} />
        </div>}

        {/* Exclusive and Inclusive Numbers Filters */}
        {!selectedCovering && <div className={classes.filterGroup}>
          <Typography component={'span'} className={classes.filterTitle}>
          SELECTIONNER LES CHIFFRES A EXCLURE
            <Divider color="primary" />
          </Typography>
          <Typography variant='caption' color='primary'>* Les boules de couleur ORANGE symbolisent les numeros gagnants des 2 precedents tirages. Sélectionnez/Désélectionnez les boules BLEUES correspondants aux numeros à EXCLURE</Typography>
          <div className={classes.chipContainer}>
            {[...Array(50).keys()].map((number) => (
              <Chip
                key={`exclusive_${number + 1}`}
                label={number + 1}
                color="primary"
                className={`${
                  exclusiveNumbers.includes(number + 1) ? 'selected' : ''
                } ${classes.chipProno}`}
                onClick={() => {
                  if (exclusiveNumbers.includes(number + 1)) {
                    setExclusiveNumbers(exclusiveNumbers.filter((n) => n !== number + 1));
                  } else {
                    setExclusiveNumbers([...exclusiveNumbers, number + 1]);
                  }
                }}
              />
            ))}
          </div>
          <div className={classes.chipContainer}>
            {(recentStats?.tirages || []).map((a_tirage, index) => (
                  index < 2 ? <div key={`recent_exclusive_inclusive_${a_tirage}_${index}`} className={classes.chipContainer}>
                      {a_tirage.map(aNumber => (
                          <Chip
                              key={`recent_exclusive_inclusive_content_${aNumber}`}
                              label={aNumber}
                              color="warning"
                              clickable={false}
                              className={`${classes.chipProno}`}
                          />
                      ))}
                  </div> : null
              ))}
          </div>
        </div>}
        <div className={classes.filterGroup}>
          <Typography component={'span'} className={classes.filterTitle}>
          {`${selectedCovering ? "CHOSISSEZ LES NUMEROS A JOEUR" : "SELECTIONNER LES CHIFFRES A INCLURE"}`}
           {selectedCovering && <Typography variant='caption' color='primary'> ({`NOMBRE SELECTION : ${inclusiveNumbers.length} / ${coverings.find(a => a['code'] == selectedCovering)?.pool_size}`})</Typography>}
            <Divider color="primary" />
           </Typography>
          <div className={classes.chipContainer}>
            {[...Array(50).keys()].map((number) => (
              <Chip
                key={`inclusive_${number + 1}`}
                label={number + 1}
                color="primary"
                className={`${
                  inclusiveNumbers.includes(number + 1) ? 'selected' : ''
                } ${classes.chipProno}`}
                onClick={() => {
                  if (inclusiveNumbers.includes(number + 1)) {
                    setInclusiveNumbers(inclusiveNumbers.filter((n) => n !== number + 1));
                  } else {
                    setInclusiveNumbers([...inclusiveNumbers, number + 1]);
                  }
                }}
              />
            ))}
          </div>
        </div>

        {/* Search Button */}
        <div className={classes.filterGroup}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={searchInProgress}
            onClick={handleSearch}
            className={classes.button}
            size='large'
          >
            RECHERCHER
          </LoadingButton>
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={searchInProgress}
              >
              <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>

      {/* Display Search Results */}

      {searchResults.length > 0 && <div className={classes.filters}>
        <div className={classes.filterGroup}>
          <Typography className={classes.filterTitle}>Nombre de tirages : {searchResults.length}</Typography>
          <TextField id="outlined-basic" label="Ex : 1,2,3" variant="outlined" value={searchResultFilter} onChange={handleSearchFilterChange} />
        </div>
      </div> }

      <div className={classes.resultsContainer}>
        {/* <LazyList
          initialItems={loadableSearchResult}
          renderItem={(aResults) => (
            <div key={`a_result_container_${aResults}`} className={classes.chipContainer}>
              {aResults.map(aResult => 
              <Chip 
                  key={`a_result_element_${aResult}_${aResults}`} 
                  color={arraysHaveSameElements(aResults, ((recentStats?.tirages && recentStats.tirages.length > 0 && recentStats?.tirages[0]) || [])) ? "primary" : "default"} 
                  label={aResult} className={`${classes.chipResult} ${classes.chipProno}`} 
                  />)}
            </div>
          )}
          fetchMoreItems={fetchMoreItems}
              /> */}

        {
            searchResults.filter(aResults => isInFilter(aResults)).map((aResults, index) => 
                <div key={`a_result_container_${aResults}`} className={classes.chipContainer}>
                    {aResults.map(aResult => 
                    <Chip 
                        key={`a_result_element_${aResult}_${index}`} 
                        
                        color={(arraysHaveSameElements(aResults, ((recentStats?.tirages && recentStats.tirages.length > 0 && recentStats?.tirages[0]) || [])) || isInAFilter(aResult) )? "primary" : "default"} 
                        label={aResult} className={`${classes.chipResult} ${classes.chipProno}`} 
                        />)}
                </div>
            )
        }
      </div>
    </Container>
  );
}

export default Pronos;
